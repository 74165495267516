import React from "react";
import { Container } from "react-bootstrap";
import Layout from "../components/Layout";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import TermsOfUseData from "../components/TermsOfUseData";

const TermsOfUse = () => (
  <Layout pageTitle="Helzy | Términos de Uso">
    <PageHeader pageHeading="Helzy | Términos de Uso" />

    <section className="blog-details">
      <Container>
        <TermsOfUseData />
      </Container>
    </section>

    <Footer />
  </Layout>
);

export default TermsOfUse;
