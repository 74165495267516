import React from "react";

const TermsOfUseData = () => {
  return (
      <section className="cta-one">
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              Versión 1.0 vigente desde el 10 de enero de 2022
              <br/><br/>
              <b>EN EL MOMENTO DE IDENTIFICARTE EN LA APLICACIÓN HELZY, MARCAS EXPLICITAMENTE LA CASILLA DE ACEPTACIÓN DE ESTOS TÉRMINOS DE USO Y POLITICA DE PRIVACIDAD, POR LO QUE MANIFIESTAS QUE HAS LEÍDO Y ACEPTAS ESTAS CONDICIONES DE USO Y POLÍTICA DE PRIVACIDAD. AQUÍ SE RECOGE TODA LA INFORMACIÓN RELATIVA A TUS DERECHOS Y OBLIGACIONES COMO USUARIO REGISTRADO DE DICHA APLICACIÓN, ASÍ COMO AL USO DE NUESTROS SERVICIOS.</b>
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              1. Qué es HELZY
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              ELSETIC, S.L. (en adelante, "TITULAR" o "ELSETIC") es la titular de la aplicación móvil HELZY (en adelante, “Aplicación” o “HELZY”), la cual ayuda en el cuidado de personas dependientes, y pone a disposición de sus usuarios y familiares (en adelante, individualmente, el “USUARIO”, y conjuntamente los “USUARIOS”), la posibilidad de navegar por la Aplicación, accediendo a los contenidos y servicios de HELZY, de acuerdo con las presentes CONDICIONES DE USO, con el objetivo de ayudar en el socorro y ayuda de personas dependientes de acuerdo a las finalidades previstas en la Política de Privacidad, y exclusivamente durante el tiempo, y para las finalidades que en dicha Política de Privacidad se prevé.
              <br/><br/>
              <b>AVISO IMPORTANTE</b>: El USUARIO queda informado de que la utilización de la Aplicación <b>NO CONSTITUYE EN NINGÚN CASO UN SERVICIO DE DIAGNÓSTICO MÉDICO, NI FORMA PARTE DE UN TIPO DE ATENCIÓN DE URGENCIAS O DE PRESCRIPCIÓN DE TRATAMIENTOS FARMACOLÓGICOS</b>. Se advierte y pone en conocimiento del USUARIO, que la utilización de la Aplicación no puede en ningún caso sustituir la consulta personal frente a un profesional médico debidamente cualificado.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              2. Alta como USUARIO y uso de HELZY
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              Para la utilización de los servicios de HELZY, es requisito acceder con el nombre y el número de telefono (debe ser válido ya que se enviará un SMS con un código que debe ser introducido en una pantalla posterior).
          </p>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              El USUARIO acepta sin reserva el contenido de las presentes CONDICIONES DE USO. En consecuencia, el USUARIO deberá leer detenidamente las mismas antes del acceso y de la utilización de cualquier servicio del HELZY bajo su entera responsabilidad.
              <br/><br/>
              <b>AVISO IMPORTANTE</b>: La utilización de la Aplicación en su Plan Básico es gratuita, libre y voluntaria, pero recuerda que <b>no podrás ser USUARIO de HELZY si no tienes al menos DIECISEIS AÑOS o cuentas con el consentimiento de tus padres o tutores legales</b>. Si no cumples con este requisito por favor no utilices la Aplicación. Los padres, tutores y/o los responsables de los menores de edad o incapaces que utilicen la HELZY serán responsables por dicho uso.
              <br/><br/>
              Queda prohibido el suministro de datos falsos y, por tanto, el USUARIO deberá identificarse con sus datos reales, actuales y veraces, y mantenerlos actualizados, así como facilitar un número de teléfono móvil para su verificación.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              3. ¿Que diferencias hay entre el Plan Básico y el Plan Monitorización?
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              Existen 2 planes disponibles para utilizar HELZY:
          </p>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              <b>Plan Básico</b>: <br/>
                - Está enfocado a las personas que van a cuidar de alguien.<br/>
                - Este plan no permite el uso de la pulsera Helzy.<br/>
                - Únicamente puede ser utilizar para recibir alertas de otros usuarios, o enviar alertas manuales.<br/>
                - Sólo permite añadir un contacto de emergencia como máximo.<br/><br/>
              <b>Plan Monitorización</b>:<br/>
                - Está enfocado a las personas que serán cuidadas por otras.<br/>
                - La contratación de este plan, incluye la entrega de una pulsera Helzy.<br/>
                - Permite configurar tantos contactos de emergencia como se necesite.<br/>
                - Se puede utilizar tanto para generar alertas manuales como alertas automáticas en función de las medidas obtenidas de la pulsera.<br/>
                - Permite configurar los rangos que el USUARIO considere normales para las mediciones de la pulsera. En caso de recibir un valor de la pulsera fuera de este rango, se emitirá una alerta a los contactos.
          </p>
          <br/>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              En ningún caso es obligatoria la contratación del Plan Monitorización para el uso de HELZY, pero es recomendable para aumentar el control sobre la persona que se quiere cuidar.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              4. Seguridad y privacidad
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              Te informamos que tus datos personales serán tratados conforme a lo establecido en la Política de Privacidad de la Aplicación, cuyo contenido íntegro se puede consultar en el siguiente enlace: <a href="/privacy-policy" target="_blank" rel="noreferrer">Política de Privacidad</a>.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              5. Propiedad intelectual e industrial
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              ELSETIC, SL. es la propietaria y titular de todos los derechos de propiedad industrial e intelectual relativos a la aplicación HELZY para dispositivos móviles.
              <br/><br/>
              Los textos, diseños, imágenes, bases de datos, logos, estructura, marcas y demás elementos de HELZY están protegidos por las leyes y los tratados internacionales sobre propiedad intelectual e industrial. Cualquier reproducción, transmisión, adaptación, traducción, modificación, comunicación al público, o cualquier otra explotación de todo o parte del contenido de este sitio, efectuada de cualquier forma o por cualquier medio, electrónico, mecánico u otro, están estrictamente prohibidos salvo autorización previa por escrito de su correspondiente titular o sujeción, de tales contenidos, a licencias de Creative Commons, en cuyo caso estarán permitidos los usos que amparen estas licencias según la modalidad de licencia aplicable en cada caso. Cualquier infracción de estos derechos puede dar lugar a procedimientos extrajudiciales o judiciales civiles o penales que correspondan.
              <br/><br/>
              En este sentido, se otorga al USUARIO únicamente una licencia limitada, temporal, no exclusiva y revocable para que pueda utilizar, descargar y/o instalar HELZY en sus dispositivos, conforme a lo previsto en estas condiciones y para los usos previstos. En todo caso, ELSETIC, SL. se reserva los derechos no expresamente otorgados al USUARIO en virtud de las presentes condiciones.
              <br/><br/>
              La legitimidad de los derechos de propiedad intelectual o industrial correspondientes a los contenidos aportados por terceros es de la exclusiva responsabilidad de los mismos.
              <br/><br/>
              A los efectos de preservar los posibles derechos de propiedad intelectual, en el caso de que cualquier USUARIO o un tercero considere que se ha producido una violación de sus legítimos derechos por la introducción de un determinado contenido en el HELZY, deberá notificar dicha circunstancia, por escrito, al TITULAR.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              6. Responsabilidad y obligaciones
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              La Aplicación se ofrece con “entrega de mejor esfuerzo” (best effort) dado que su calidad y disponibilidad pueden verse afectadas por múltiples factores ajenos a ELSETIC como son, entre otros, las limitaciones o restricciones de las redes de terceros operadores o la compatibilidad del dispositivo y sistema operativo utilizado por el USUARIO. Igualmente, los USUARIOS aceptan que el servicio pueda verse interrumpido cuando sea necesario por labores de mantenimiento.
              <br/><br/>
              Por todo ello, ELSETIC no será responsable de los problemas de acceso o disponibilidad de HELZY y/o sus servicios, ni de los perjuicios que se pudieran causar por ello, cuando éstos procedan de factores ajenos a su ámbito de control. Igualmente, ELSETIC no se hace responsable de los fallos, incompatibilidades y/o daños de tus terminales o dispositivos que, en su caso, se pudiesen derivar de la descarga y/o uso de la Aplicación.
              <br/><br/>
              Igualmente, ELSETIC no garantiza ni se hace responsable, en ningún caso ni circunstancia, de los siguientes hechos y contenidos, ni de cualesquiera daños y perjuicios que pudieran, en su caso, derivarse de los mismos:<br/>
              - Actualización, exactitud, exhaustividad, pertinencia, actualidad y fiabilidad de sus contenidos, cualquiera que sea la causa y las dificultades o problemas técnicos o de otra naturaleza en los que tengan su origen dichos hechos.<br/>
              - La calidad, titularidad, legitimidad, adecuación o pertinencia de los materiales, y demás contenidos.
              <br/><br/>
              Como USUARIO de la Aplicación te obligas a:
              <br/><br/>
              - Notificar a ELSETIC con carácter inmediato cualquier indicio de la existencia de una violación en la seguridad en la Aplicación, de usos inapropiados o prohibidos de los servicios prestados desde la misma, o de fallos de seguridad de cualquier índole.<br/>
              - Hacer buen uso de los contenidos, información y servicios prestados desde o a través de la Aplicación, conforme a la ley, la buena fe y a las buenas costumbres generalmente aceptadas, comprometiéndose expresamente a:<br/>
              - Abstenerse de realizar prácticas o usos de los servicios con fines ilícitos, fraudulentos, lesivos de derechos o intereses de ELSETIC o de terceros, infractores de las normas contenidas en el presente documento.<br/>
              - Abstenerse de realizar cualquier tipo de acción que pudiera inutilizar, sobrecargar o dañar sistemas, equipos o servicios de la Aplicación o accesibles directa o indirectamente a través de esta.<br/>
              - Respetar los derechos de propiedad intelectual e industrial de ELSETIC y de terceros sobre los contenidos, información y servicios prestados desde o a través de la Aplicación, absteniéndose con carácter general de copiar, distribuir, reproducir o comunicar en forma alguna los mismos a terceros, de no mediar autorización expresa y por escrito de ELSETIC o de los titulares de dichos derechos.<br/>
              - No proporcionar información personal falsa en la Aplicación, siendo el único responsable de la comunicación real y veraz de sus datos personales.<br/>
              - No crear una cuenta falsa, ni suplantar la personalidad de un tercero sin su autorización.
              <br/><br/>
              Como USUARIO de la Aplicación, tú eres el único responsable del uso que decidas realizar de los servicios de HELZY. El incumplimiento de tus obligaciones como USUARIO podrá implicar la baja inmediata de la Aplicación y/o sus servicios; todo ello sin derecho a recibir compensación de ningún tipo, y sin perjuicio de las correspondientes acciones legales a que por parte de ELSETIC hubiere lugar.
              <br/><br/>
              ELSETIC no será responsable en ningún caso de la utilización indebida de HELZY y de sus contenidos, siendo el USUARIO el único responsable por los daños y perjuicios que pudieran derivarse de un mal uso de estos o de la infracción de lo dispuesto en las presentes condiciones en que pueda incurrir. El USUARIO se compromete a mantener indemne al TITULAR frente a las reclamaciones o sanciones que pudiera recibir de terceros, ya sean particulares o entidades públicas o privadas, por razón de dichas infracciones, así como frente a los daños y perjuicios de todo tipo que pueda sufrir como consecuencia de las mismas.
              <br/><br/>
              En cualquier caso, ELSETIC se reserva el derecho de, en cualquier momento y sin necesidad de previo aviso, modificar o eliminar el contenido, estructura, diseño, servicios y condiciones de acceso y/o uso de este sitio, siempre que lo estime oportuno, siempre que dicho cambio no afecte a los principios y derechos de los usuarios en materia de la protección de sus datos personales, ni suponga una infracción de dicha normativa así como el derecho interpretar las presentes condiciones, en cuantas cuestiones pudiera plantear su aplicación.
              <br/><br/>
              Asimismo, queda prohibida la reproducción, distribución, transmisión, adaptación o modificación, por cualquier medio y en cualquier forma, de los contenidos del HELZY o sus cursos (textos, diseños, gráficos, informaciones, bases de datos, archivos de sonido y/o imagen, logos y demás elementos de estos sitios), salvo autorización previa de sus legítimos titulares o cuando así resulte permitido la normativa vigente aplicable.
              <br/><br/>
              La enumeración anterior tiene mero carácter enunciativo y no es, en ningún caso, exclusivo ni excluyente en ninguno de sus puntos. En todos los supuestos, El Titular EXCLUYE CUALQUIER RESPONSABILIDAD POR LOS DAÑOS Y PERJUICIOS DE CUALQUIER NATURALEZA DERIVADOS DIRECTA O INDIRECTAMENTE DE LOS MISMOS Y DE CUALESQUIERA OTROS NO ESPECIFICADOS DE ANÁLOGAS CARACTERISTICAS.
              <br/><br/>
              <b>HELZY no es un dispositivo médico, ni pretende serlo, por lo que el USUARIO es el único responsable de la generación de dichas alertas por sobrepasar las medidas establecidas como "normales" y ELSETIC no se hará responsable en ningún caso del envío de alertas a usuarios por mediciones fuera de estos rangos.</b>
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200" id="recommendations">
              7. Recomendaciones y mensajes dentro de la aplicación
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              En ocasiones la aplicación informará al USUARIO o a los contactos de emergencia configurados por el USUARIO de alertas, pero son mensajes únicamente con finalidades de prevención y <b>EN NINGÚN CASO CONSTITUYEN UN SERVICIO DE DIAGNÓSTICO MÉDICO, DE ATENCIÓN DE URGENCIAS O DE PRESCRIPCIÓN DE TRATAMIENTOS FARMACOLÓGICOS</b>. Se advierte y pone en conocimiento del USUARIO, que la utilización de la Aplicación no puede en ningún caso sustituir la consulta presencial personal frente a un profesional médico debidamente cualificado.
          </p>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              La primera vez que se conecta una pulsera Helzy, se le solicitará al USUARIO que indique los rangos que considera "normales" de las constantes vitales, para que en caso de recibir medidas fuera de este rango, se pueda generar la alerta correspondiente a los contactos de emergencia.<br/>
              <b>HELZY no es un dispositivo médico, ni pretende serlo, por lo que el USUARIO es el único responsable de la generación de dichas alertas por sobrepasar las medidas establecidas como "normales" y ELSETIC no se hará responsable en ningún caso del envío de alertas a usuarios por mediciones fuera de estos rangos.</b>
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              8. Información corporativa y contacto
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              ELSETIC, SL, CIF:B01794015, dirección: Calle Nord 49, Casa 29, 08950, Esplugues de Llobregat, Barcelona.
              <br/><br/>
              El soporte al USUARIO en caso de incidencias y/o reclamaciones será principalmente online (a través de <a href="mailto:helzy@elsetic.com">helzy@elsetic.com</a>) y será atendido a la mayor brevedad.
          </p>
          <br/>
          <h3 data-aos="fade-left" data-aos-delay="200">
              9. Modalidades de pago
          </h3>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              HELZY es gratis en su Plan Básico. En el caso de escoger el Plan Monitorización se realizará un cargo siguiendo las siguientes premisas dependiendo de la opción de pago escogida (Mensual o Anual):
          </p>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              <b>Pago Mensual</b>:<br/>
              - Coste: 15€/mes<br/>
              - Se realizará el cargo automáticamente a la tarjeta configurada en el momento de contratar el plan y cada mes que se mantenga la suscripción activa.<br/>
              - Se podrá realizar la baja de la suscripción en cualquier momento.<br/>
              - En caso de solicitar la baja de la suscripción, el USUARIO podrá seguir utilizando el Plan Monitorización hasta finalizar el mes actual (ya facturado).<br/>
              <br/>
              <b>Pago Anual</b>:<br/>
              - Coste: 169€/anual<br/>
              - Se realizará el cargo automáticamente a la tarjeta configurada en el momento de contratar el plan y cada año que se mantenga la suscripción activa.<br/>
              - Al adquirir este plan, el USUARIO acepta que la facturación no es reembolsable y se dispondrá de acceso al Plan Monitorización durante todo el año restante, se use o no la aplicación.<br/>
              - Se podrá realizar la baja de la suscripción en cualquier momento, siempre teniendo en cuenta el punto anterior.<br/>
          </p>
          <p data-aos="fade-right" data-aos-delay="400" align="left">
              Estas premisas referentes al cobro, facturación y devolución, podrán ser cambiadas previo acuerdo entre las 2 partes: USUARIO y ELSETIC, pudiendo llegar a modificarse si así se pactase entre ambas partes.
          </p>
          <a href='/'  className="thm-btn banner-one__btn">
            <span>Volver al inicio</span>
          </a>
      </section>
  );
};

export default TermsOfUseData;
